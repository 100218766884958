import { ref, watch } from "vue";
import { isEmpty } from "lodash-es";

type AsyncEvent = {
  eventId: string;
  eventParam: any;
};

const asyncEvent = ref<AsyncEvent>();

const clevertapConstants = [
  {
    eventId: "GIFT_CARD_PURCHASED",
    eventName: "Gift Card Purchased",
  },
];

function track(eventId: string, eventParam: any, isAsync = false) {
  const event = clevertapConstants.filter(
    (constant) => constant.eventId === eventId
  );
  if (event.length) {
    if (isAsync) {
      asyncEvent.value = { eventId, eventParam };
      return;
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        event: event[0].eventName,
        ...eventParam,
      });
    }
  } else {
    throw new Error("invalid eventId");
  }
}

function sendAsyncEvent() {
  if (asyncEvent.value && !isEmpty(asyncEvent.value)) {
    track(asyncEvent.value.eventId, asyncEvent.value.eventParam, false);
    asyncEvent.value = {} as AsyncEvent;
  }
}

watch(asyncEvent, (newVal) => {
  if (newVal && !isEmpty(newVal)) {
    sendAsyncEvent();
  }
});

export default track;
